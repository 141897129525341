import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import '@mhe/mh-icon/src/icon-components/chevron-down';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';

import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
  LtiActions,
  ltiParams,
  PendoConfig,
  PendoService,
  Role,
  TagManagerConfig,
  TagManagerService,
  TranslationConfigService,
  UPUtilityService,
} from 'up';
import { environment } from '../environments/environment';
import { getLtiParams } from './shared/store/selectors/ltiparams.selector';
import { LtiParamsState } from './shared/store/state/ltiparams.state';

@Component({
  selector: 'ebook-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NgbAccordionConfig],
})
export class AppComponent implements OnInit, OnDestroy {
  isHeaderDisplay = signal<boolean>(true);
  isFooterDisplay = signal<boolean>(true);
  ltiParams$: Observable<ltiParams> = this.store.select(getLtiParams);
  ltiParams!: ltiParams;
  private destroy$ = new Subject<boolean>();

  constructor(
    private pendoService: PendoService,
    private router: Router,
    private store: Store<LtiParamsState>,
    private tagManagerService: TagManagerService,
    private translationConfigService: TranslationConfigService,
    private upUtilityService: UPUtilityService,
  ) {}

  ngOnInit() {
    const tagManagerConfig: TagManagerConfig = {
      env: environment.tagManagerEnv,
      auth: environment.tagManagerAuth,
    };
    this.tagManagerService.addTag(tagManagerConfig);

    this.getLtiParams();
  }

  initiatePendo() {
    const pendoConfig: PendoConfig = {
      VisitorConfig: {
        id: this.ltiParams.userXid,
        mh_environment: environment.envName,
        org: this.ltiParams.organizationXid,
        mh_platform: this.ltiParams.platform,
        ebook_role: this.ltiParams.role,
        mh_courseDescriptor: this.ltiParams.isbn!,
      },
      AccountConfig: {
        id: this.ltiParams.organizationXid,
        lmsName: this.ltiParams.lmsName,
      },
    };
    this.pendoService.addPendo(pendoConfig);
  }

  getLtiParams() {
    this.ltiParams$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ltiParams: ltiParams) => {
        this.ltiParams = ltiParams;
        this.translationConfigService.setLocale(this.ltiParams?.lmsLocale);
        this.pageDistributor();
        if (environment.enablePendo) {
          this.initiatePendo();
        }
      });
  }

  pageDistributor() {
    const isInstructor =
      this.ltiParams?.role?.toLowerCase() === Role.INSTRUCTOR;
    const isSelectAction = this.ltiParams?.ltiaAction === LtiActions.SELECT;
    const isApplicationInFrame = this.upUtilityService.isApplicationInFrame();
    const isHomePath = this.upUtilityService.getPathName() === '/';
    const isCourseCopy = this.ltiParams?.isCourseCopy;

    if (isCourseCopy) {
      this.isHeaderDisplay.set(false);
      this.isFooterDisplay.set(false);
      this.router.navigateByUrl('/instructor/relinking/launch');
    } else if (isInstructor && isSelectAction && isApplicationInFrame) {
      this.isHeaderDisplay.set(false);
      this.isFooterDisplay.set(false);
      this.router.navigateByUrl('/instructor/launch');
    } else if (isHomePath) {
      this.router.navigateByUrl('/ebook');
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
