import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'projects/ebook/src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { UPUtilityService } from 'up';

@Component({
  selector: 'ebook-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  errorMessageKey: string = 'GENERIC.ERROR.MESSAGE_EBOOK';

  constructor(
    private upUtilityService: UPUtilityService,
    private translate: TranslateService,
    private router: Router,
  ) {
    if (this.router.getCurrentNavigation()?.extras.state?.['translateKey'])
      this.errorMessageKey =
        this.router.getCurrentNavigation()?.extras.state?.['translateKey'];
  }

  ngOnInit(): void {
    this.setPageTitle();
  }

  async setPageTitle() {
    const translatedPageTitle = await firstValueFrom(
      this.translate.get(['GENERIC.PAGE_TITLE', 'GENERIC.ERROR.PAGE_TITLE']),
    );

    this.upUtilityService.setPageTitle(
      `${translatedPageTitle['GENERIC.ERROR.PAGE_TITLE']} ${translatedPageTitle['GENERIC.PAGE_TITLE']}`,
    );
  }

  onExitClick() {
    this.upUtilityService.returnToCourse(`${environment.apiUrl}/ebook`);
  }
}
